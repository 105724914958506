<template>
  <label
    class="flex cursor-pointer items-center space-x-3"
    :class="{ '!cursor-not-allowed': disabled }"
  >
    <div
      class="relative h-5 w-5 shrink-0 overflow-hidden rounded-sm focus-within:ring-2"
      :class="[
        isWhite ? 'bg-white' : 'border-2 border-blue-800',
        { 'border-[#939393]': disabled && !isWhite },
      ]"
    >
      <input
        type="checkbox"
        class="absolute inset-0 opacity-0"
        :value="modelValue"
        :checked="modelValue"
        :disabled="disabled"
        :name="name"
        @input="emit('update:modelValue', $event.target)"
      />
      <transition name="bounce">
        <div
          v-if="modelValue"
          class="absolute inset-0 flex items-center justify-center bg-blue-800"
        >
          <CheckmarkIcon class="absolute w-3 text-white" />
        </div>
      </transition>
    </div>
    <span
      class="mb-[0.125rem]"
      :class="{ 'text-[#939393]': disabled && !isWhite }"
    >
      <slot />
    </span>
  </label>
</template>

<script setup>
const emit = defineEmits(['update:modelValue'])
defineProps({
  modelValue: {
    type: [Boolean, String],
    default: false,
  },

  name: {
    type: String,
    default: null,
  },

  isWhite: {
    type: Boolean,
    default: false,
  },

  disabled: {
    type: Boolean,
    default: false,
  },
})
</script>
